

<template>
  <div id="box" class="home-container">
<!--    <Header></Header>-->

    <div class="box_content">
      <div class="box_content_title">注册信息</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="单位名称" prop="company">
              <el-input v-model="ruleForm.company" placeholder="请输入单位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信用代码" prop="yscode">
              <el-input v-model="ruleForm.yscode"  placeholder="请输入信用代码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="经办人" prop="nickName">
              <el-input v-model="ruleForm.nickName"  placeholder="请输入经办人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号码" prop="phonenumber">
              <el-input v-model="ruleForm.phonenumber" maxlength="11" placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="电子邮箱" prop="email">
              <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="密码" prop="password">
              <el-input v-model="ruleForm.password" maxlength="16" show-password placeholder="请输入密码"></el-input>
            </el-form-item>

          </el-col>
        </el-row>




        <div style="display: flex;flex-direction: column;margin-top: 100px;margin-left: 0;">
         <el-button style="width: 520px;background:#f39800;border-color:#f39800;margin: 10px auto;" type="primary" @click="submitForm('ruleForm')">立即注册</el-button>
         <el-button style="margin: 10px auto;width: 520px"   @click="resetForm('ruleForm')">返回登陆</el-button>
        </div>
      </el-form>
    </div>

<!--    <Footer></Footer>-->
  </div>
</template>

<script>
import { user_register} from "@/api/manage";
  export default {
    data() {
      var validatePass1 = (rule, value, callback) => {
        var regex = /^1[3456789]\d{9}$/
        if (value === '') {
          callback(new Error('请输入手机号！'));
        } else if(!regex.test(value)){
          callback(new Error('手机号不正确！'));
        }else{
          callback()
        }
      };
      var validatePass2 = (rule, value, callback) => {
        var pattern = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
        if (value === '') {
          callback(new Error('请输入邮箱！'))
        } else if(!pattern.test(value)){
          callback(new Error('请检查邮箱格式！'))
        }else{
          callback();
        }
      };
      var validatePass3 = (rule, value, callback) => {
        var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-+.]).{6,16}$/;
        if (value === '') {
          callback(new Error('请输入密码！'))
        } else if(!regex.test(value)){
          callback(new Error('请检查密码格式！最少包含一位大写英文字符+一位小写英文字符+一位数字+长度6-16为之间'))
        }else{
          callback();
        }
      };
      return {
        ruleForm: {
          company:'',
          yscode:'',
          nickName: '',
          userName:'',
          phonenumber:'',
          email:'',
          password:'',
        },
        rules: {
          company: [
            { required: true, message: '请输入单位名称', trigger: 'blur' },
          ],
          yscode: [
            { required: true, message: '请输入信用代码', trigger: 'blur' },
          ],
          nickName: [
            { required: true, message: '请输入经办人姓名', trigger: 'blur' },
          ],
          phonenumber: [
            { required: true, message: '请输入经办人手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: '长度在 11个字符', trigger: 'blur' },
            { validator: validatePass1, trigger: 'blur' }
            // {pattern: /^1[3456789]\d{9}$/, message: "请输入正确的手机号码!"}
          ],
          email: [
            { required: true, message: '请输入电子邮箱', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }
            // {
            //   pattern: /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/,
            //   message: "请输入正确的邮箱!",
            // }, //此处配置正则表达式，手机号，可自己配置正则表达式
          ],
          password:[
            { required: true, message: '请输入登陆密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6-16个字符之间', trigger: 'blur' },
            { validator: validatePass3, trigger: 'blur' }
          ]


        }
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.ruleForm.userName = this.ruleForm.phonenumber
            user_register(this.ruleForm).then((response) => {
              // console.log(response)
               if(response.code == 200){
                 this.$router.push("/user/login");
                 this.$message({
                   message: '注册成功！',
                   type: 'success'
                 });
               }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$router.push("/user/login");
        // this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style scoped lang="css">
#box {
  background: #f5f5f5;
  //background-image: url("img/bj1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //height: 100vh;
}



.box_content{
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  box-sizing: border-box;
}

.box_content_title{
  display: block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  font-family: cursive;
  margin-bottom: 50px;

}

</style>