// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/bj1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#box[data-v-006f7b36] {\r\n  background: #f5f5f5;\r\n  //background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: 100% 100%;\r\n  //height: 100vh;\n}\n.box_content[data-v-006f7b36]{\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-orient: vertical;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-direction: column;\r\n          flex-direction: column;\r\n  padding: 50px 100px;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\n}\n.box_content_title[data-v-006f7b36]{\r\n  display: block;\r\n  width: 100%;\r\n  height: 100px;\r\n  line-height: 100px;\r\n  font-size: 50px;\r\n  font-weight: bold;\r\n  text-align: center;\r\n  font-family: cursive;\r\n  margin-bottom: 50px;\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
